import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Extraescolars" />
    <div className="bg-red-100 py-8">
      <h1 className="text-center text-5xl">Extraescolars</h1>
    </div>
    <div className="mx-auto max-w-screen-xl py-8 px-4 sm:px-12">
      <div className="py-6" id="expressio-corporal">
        <h3 className="text-3xl mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-10 w-10 mr-2 inline-block hover:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Expressió corporal
        </h3>
        <p>Destinat a cursos d’educació infantil i primer i segon de primària.</p>
        <p>Expressar-se dóna riquesa motriu. És un llenguatge del cos. Així, doncs, l'expressió corporal i el teatre no tenen sentit si no serveix per entendre's millor i comunicar-se amb els altres.</p>
        <p>Objectius personals per els alumnes:</p>
        <ul>
          <li>Conèixer, acceptar i valorar el propi cos i l’activitat física com a mitjà d’exploració per l’elaboració de l’autoimatge, l’autoestima i l’autoconfiança.</li>
          <li>Explorar les possibilitats i recursos expressius del propi cos per comunicar sensacions, emocions i idees.</li>
          <li>Compartir i gaudir de l’expressió i comunicació corporal en col·lectivitat mitjançant el joc, l’improvisació i qualsevol activitat física que comporti el desenvolupament de la persona.</li>
        </ul>
      </div>

      <div className="py-6" id="teatre-escola">
        <h3 className="text-3xl mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-10 w-10 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
          </svg>
          Teatre a l'escola
        </h3>
        <p>Destinat a cursos d’educació infantil i primer i segon de primària.</p>
        <p>Expressar-se dóna riquesa motriu. És un llenguatge del cos. Així, doncs, l'expressió corporal i el teatre no tenen sentit si no serveix per entendre's millor i comunicar-se amb els altres.</p>
        <p>Objectius personals per els alumnes:</p>
        <ul>
          <li>Conèixer, acceptar i valorar el propi cos i l’activitat física com a mitjà d’exploració per l’elaboració de l’autoimatge, l’autoestima i l’autoconfiança.</li>
          <li>Explorar les possibilitats i recursos expressius del propi cos per comunicar sensacions, emocions i idees.</li>
          <li>Compartir i gaudir de l’expressió i comunicació corporal en col·lectivitat mitjançant el joc, l’improvisació i qualsevol activitat física que comporti el desenvolupament de la persona.</li>
        </ul>
      </div>

      <div className="py-6" id="contes-escola">
        <h3 className="text-3xl mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-10 w-10 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
          </svg>
          Contes a l'escola
        </h3>
        <p>Destinat a cursos d'educació infantil.</p>
        <p>Cada dia de classe s'explicaran contes diferents i es treballaran mitjançant jocs, moviments i activitats.</p>
        <p>Objectius personals per els alumnes:</p>
        <ul>
          <li>Reproduir o improvisar gestos mitjançant la imitació.</li>
          <li>Reproduir o improvisar gestos sense inhibició en moviments espontanis i suggerits.</li>
          <li>Representar objectes, accions, situacions, oficis, etc., individualment o en grup.</li>
          <li>Representar sentiments i emocions.</li>
          <li>Interpretar els moviments expressius dels companys.</li>
          <li>Deixar anar la imaginació i compartir-la amb els companys.</li>
        </ul>
      </div>
      <div className="mt-4">
        <Link className="underline" to="/">← Tornar a portada</Link>
      </div>

    </div>
  </Layout>
)

export default SecondPage
